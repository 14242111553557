




































































































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref } from "vue-property-decorator";

@Observer
@Component({
  components: {
    TimeAuctionForm: () => import("../components/time-aution-form.vue"),
    FixedPriceForm: () => import("../components/fixed-price-form.vue"),
    PreviewCharacter: () => import("../components/preview-character.vue"),
  },
})
export default class SellMonsterDialog extends Vue {
  dialog?: boolean = false;
  isAution?: boolean = true;
  agreeTerm?: boolean = false;
}
